<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Encomenda" : "Cadastrar Encomenda" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações da Encomenda</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <span class="primary--text">Código de Barras</span>
              <v-text-field
                v-model="dados.cod_barras"
                required
                disabled
                placeholder="Informe o código de barras"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <span class="primary--text">Agência de Recebimento</span>
              <v-autocomplete
                placeholder="Informe a agência de recebimento"
                class="mt-1 custom-solo-selection"
                solo
                v-model="dados.id_agencia_entrega"
                :rules="fieldRules"
                :items="agencias"
                item-value="id"
                item-text="nome"
                required
                :disabled="id ? true : false"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <span class="primary--text">Cobrança</span>
              <v-autocomplete
                placeholder="Informe a cobrança"
                class="mt-1 custom-solo-selection"
                solo
                v-model="dados.cobranca"
                :rules="fieldRules"
                :items="cobrancas"
                item-value="id"
                item-text="description"
                required
                @change="checkPaymentState()"
                :disabled="id ? true : false"
              />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <span class="primary--text">Forma de Pagamento</span>
              <v-select
                v-model="dados.id_formapagamento"
                :items="paymentForm"
                placeholder="Informe a forma de pagamento"
                class="mt-1 custom-solo-selection"
                solo
                item-value="id"
                item-text="descricao"
                required
                :disabled="dados.cobranca !== '1' || id"
                :rules="dados.cobranca === '1' ? fieldRules : true"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <span class="primary--text">Remetente</span>
              <v-autocomplete
                placeholder="Informe o remetente"
                class="mt-1 custom-solo-selection"
                v-model="dados.id_remetente"
                :items="clientes"
                item-text="razao_social_nome"
                item-value="id"
                required
                :rules="fieldRules"
                :disabled="id ? true : false"
                :loading="loading"
                :search-input.sync="searchClient"
                hide-no-data
              />
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <span class="primary--text">Destinatário</span>
              <v-autocomplete
                placeholder="Informe o destinatário"
                class="mt-1 custom-solo-selection"
                v-model="dados.id_destinatario"
                :items="clientesDestinatario"
                item-text="razao_social_nome"
                item-value="id"
                required
                :rules="fieldRules"
                :disabled="id ? true : false"
                :loading="loadingDestinatario"
                :search-input.sync="searchClientDestinatario"
                hide-no-data
              />
            </v-col>

            <v-col cols="12" sm="6">
              <span class="primary--text">Localidade de Origem *</span>
              <v-autocomplete
                placeholder="Informe a localidade de origem"
                class="mt-1 custom-solo-selection"
                solo
                v-model="dados.id_localidade_origem"
                :rules="fieldRules"
                :items="trechos"
                item-value="id"
                item-text="nome"
                required
                disabled
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <span class="primary--text">Localidade de Destino *</span>
              <v-autocomplete
                placeholder="Informe a localidade de destino"
                class="mt-1 custom-solo-selection"
                solo
                v-model="dados.id_localidade_destino"
                :rules="fieldRules"
                :items="trechosDestino"
                item-value="id"
                item-text="nome"
                required
                :disabled="id ? true : false"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <span class="primary--text">Motorista *</span>
              <v-autocomplete
                placeholder="Informe o motorista"
                class="mt-1 custom-solo-selection"
                solo
                v-model="dados.id_motorista"
                :rules="fieldRules"
                :items="motoristas"
                item-value="id"
                item-text="nome"
                required
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <span class="primary--text">Ônibus *</span>
              <v-autocomplete
                placeholder="Informe o ônibus"
                class="mt-1 custom-solo-selection"
                solo
                v-model="dados.id_bus"
                :rules="fieldRules"
                :items="onibus"
                item-value="id"
                item-text="descricao"
                required
              >
                <template slot="selection" slot-scope="{ item }"
                  >{{ item.prefixo }} - {{ item.placa }}</template
                >
                <template slot="item" slot-scope="{ item }"
                  >{{ item.prefixo }} - {{ item.placa }}</template
                >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <span class="primary--text">Natureza *</span>
              <v-autocomplete
                placeholder="Informe a natureza"
                class="mt-1 custom-solo-selection"
                solo
                v-model="dados.natureza"
                :rules="fieldRules"
                :items="naturezaOp"
                item-value="descricao"
                item-text="descricao"
                required
                :disabled="id ? true : false"
              />
            </v-col>

            <v-col cols="12" sm="2">
              <span class="primary--text">Quantidade *</span>
              <v-text-field
                placeholder="Informe a quantidade"
                class="mt-n1"
                v-model="dados.quant_volumes"
                v-mask-number
                :rules="fieldRules"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="3">
              <span class="primary--text">Valor Total R$ *</span>
              <v-text-field
                placeholder="Informe o valor total"
                class="mt-n1"
                v-model="dados.valor_total"
                v-mask-decimal.br="2"
                :disabled="id ? true : false"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <span class="primary--text">Observações</span>
              <v-textarea
                v-model="dados.observacoes"
                placeholder="Informe algumas observações"
                class="mt-n1"
                :disabled="id ? true : false"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn dark depressed text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>
  </v-container>
</template>

<script>
import BackArrow from "../../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      loadingDestinatario: false,
      searchClient: null,
      searchClientDestinatario: null,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        cod_barras: "",
        cobranca: "",
        id_remetente: null,
        id_destinatario: null,
        id_localidade_origem: null,
        id_localidade_destino: null,
        id_motorista: null,
        id_agencia: null,
        id_bus: null,
        status_pagamento: false,
        natureza: "",
        valor_total: 0,
        observacoes: "",
        id_agencia_entrega: 0,
        id_formapagamento: null,
      },
      agencias: [],
      agencia: [],
      onibus: [],
      motoristas: [],
      trechos: [],
      trechosDestino: [],
      naturezaOp: [],
      empresas: [],
      clientes: [],
      clientesDestinatario: [],
      paymentForm: [],
      cobrancas: [
        { id: "1", description: "PAGO" },
        { id: "2", description: "À PAGAR" },
        // { id: "3", description: "CONTRATO" },
      ],
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      cnpjRules: [(v) => !!v || "Este campo é requerido"],
      emailRules: [
        (v) => !!v || "E-mail é requerido",
        (v) => /.+@.+\..+/.test(v) || "Digite um e-mail válido!",
      ],
    };
  },
  created() {
    this.checkCashboxOpen();
    this.loadEmpresas();
    this.loadAgencies();
    this.loadAgency();
    this.loadMotoristas();
    this.loadOnibus();
    // this.loadClients();
    this.loadNaturezaOp();
    this.getpaymentForm();
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/encomendas/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          // this.clientesDestinatario = this.clientes;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    } else {
      this.gerarCodigodeBarras();
    }
  },
  watch: {
    searchClient(val) {
      if (!val || val.length < 3) {
        this.clientes = [];
        return;
      }

      this.loading = true;
      this.querySelections(val);
    },
    searchClientDestinatario(val) {
      if (!val || val.length < 3) {
        this.clientesDestinatario = [];
        return;
      }

      this.loadingDestinatario = true;
      this.querySelectionsDestinatario(val);
    },
  },
  methods: {
    querySelections(v) {
      clearTimeout(this._searchTimerIdCliente);
      this._searchTimerIdCliente = setTimeout(() => {
        this.$http
          .get(`/encomendas/clientes/search/${v}`)
          .then((r) => {
            this.clientes = r.data;
          })
          .finally(() => (this.loading = false));
      }, 500);
    },
    querySelectionsDestinatario(v) {
      clearTimeout(this._searchTimerIdDestinatario);
      this._searchTimerIdDestinatario = setTimeout(() => {
        this.$http
          .get(`/encomendas/clientes/search/${v}`)
          .then((r) => {
            this.clientesDestinatario = r.data;
          })
          .finally(() => (this.loadingDestinatario = false));
      }, 500);
    },
    getpaymentForm() {
      this.$http.get(`/formaspagamento`).then((r) => {
        this.paymentForm = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    checkCashboxOpen() {
      this.$http
        .post(`agencias/check-cashbox-open`, {
          agency_id: this.$store.getters.getOpAgency,
          operator_id: this.$store.getters.getOperador,
        })
        .then((response) => {
          if (!response.data.status) {
            if (this.$store.getters.getOperadorLevel >= 5) {
              this.$router.replace("/pdv/financeiro/caixa-bilheteiro");
            }
            return;
          }
        });
    },
    gerarCodigodeBarras() {
      this.dados.cod_barras = Math.floor(Math.random() * 99999999999999);
    },
    checkPaymentState() {
      this.dados.status_pagamento = this.dados.cobranca == 1 ? true : false;
    },
    loadAgencies() {
      this.$http.get("/agencias").then((r) => {
        this.agencias = r.data;
      });
    },
    loadNaturezaOp() {
      this.$http.get("/natureza-operacoes").then((r) => {
        this.naturezaOp = r.data;
      });
    },
    loadClients() {
      this.$http
        .get(`/encomendas/clientes`)
        .then((r) => {
          this.clientes = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    calculateValue() {
      if (this.dados.quant_volumes && this.dados.valor) {
        this.$http
          .post(`/encomendas-calcular`, {
            valor: this.dados.valor,
            quantidade: this.dados.quant_volumes,
          })
          .then((r) => {
            this.dados.valor_total = r.data[0];
            this.$store.dispatch("activeLoader", false);
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.$store.dispatch("showMessage", {
              show: true,
              color: "error darken-1",
              text: e,
            });
          });
      }
    },
    loadEmpresas() {
      this.$http
        .get(`/empresas`)
        .then((r) => {
          this.empresas = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    loadOnibus() {
      this.$http
        .get(`/bus`)
        .then((r) => {
          this.onibus = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    loadMotoristas() {
      this.$http
        .get(`/motoristas/ativos`)
        .then((r) => {
          this.motoristas = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    loadAgency() {
      this.$http
        .get(`/pdv/agencias/${this.$store.getters.getOpAgency}`)
        .then((r) => {
          this.agencia = r.data[0];
          this.dados.id_localidade_origem = this.agencia.id_localidade;
          this.loadTrechos();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    loadTrechoInicial() {
      this.$http
        .get(`/pdv/localidades/${this.agencia.id_localidade}`)
        .then((r) => {
          this.trechosDestino = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    loadTrechos() {
      this.$http
        .get(`/localidades`)
        .then((r) => {
          this.trechos = r.data;
          this.loadTrechoInicial();
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.$store.dispatch("showMessage", {
            show: true,
            color: "error darken-1",
            text: e,
          });
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.$store.dispatch("showMessage", {
          show: true,
          color: "warning darken-1",
          text: "Atencão um ou mais campos precisam ser preenchidos!",
        });
      }
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      this.dados.id_agencia = this.agencia.id;
      this.dados.id_operador = this.$store.getters.getOperador;
      this.dados.id_agencia_pagamento =
        this.dados.cobranca == "1" ? this.$store.getters.getOpAgency : null;
      this.dados.natureza = this.dados.natureza.toUpperCase();
      if (this.dados.cobranca !== "1") this.dados.id_formapagamento = null;
      if (this.id) {
        this.$http
          .post(`/encomendas/update/${this.id}`, this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.$router.push("/encomendas/gerenciar");
              this.$store.dispatch("showMessage", {
                show: true,
                color: "success darken-1",
                text: r.data.success,
              });
            }
          });
      } else {
        this.$http
          .post("/encomendas/create", {
            dados: this.dados,
            id_operador: this.$store.getters.getOperador,
            id_agencia: this.$store.getters.getOpAgency,
          })
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.$store.dispatch("showMessage", {
                show: true,
                color: "success darken-1",
                text: r.data.success,
              });
              setTimeout(() => {
                // um número no formato internacional (ativo no WhatsApp)
                let number = "55" + r.data.telefone;
                // o texto ou algo vindo de um <textarea> ou <input> por exemplo
                let msg =
                  "Olá, " +
                  r.data.nome +
                  ", muito obrigado por utilizar os nossos serviços, Acompanhe sua encomenda pelo código: " +
                  r.data.codigo +
                  " att: TRANSBRAZ";
                // montar o link (apenas texto) (web)
                let target = `https://api.whatsapp.com/send?text=${encodeURIComponent(
                  msg
                )}`;
                // montar o link (número e texto) (web)
                target = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
                  number
                )}&text=${encodeURIComponent(msg)}`;
                let a = document.createElement("a");
                a.target = "_blank";
                a.href = target; // o link URL do api.whatsapp.com
                a.click(); // simular o evento de "click"
                this.$router.push("/encomendas/gerenciar");
              }, 1500);
            } else {
              this.$store.dispatch("showMessage", {
                show: true,
                color: "error darken-1",
                text: r.data.error,
              });
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.$store.dispatch("showMessage", {
              show: true,
              color: "error darken-1",
              text: e,
            });
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
